import { useMemo, useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { DefaultButton, PrimaryButton, FontSizes, Stack, TextField, Checkbox } from "@fluentui/react";
import { updateAzureTenant } from "../graphql/mutations";
import { getAzureTenant } from "../graphql/queries";

export default function (props) {
  const { azureTenantId: idProp, azureTenant: azureTenantModelProp } = props;
  const initialValues = {
    name: "",
    azureTenantId: "",
    azureAppId: "",
    azureAppSecret: "",
    userFilterString: "",
  };
  const client = useMemo(() => {
    return generateClient();
  }, []);

  const [name, setName] = useState(initialValues.name);
  const [azureTenantId, setAzureTenantId] = useState(initialValues.azureTenantId);
  const [azureAppId, setAzureAppId] = useState(initialValues.azureAppId);
  const [azureAppSecret, setAzureAppSecret] = useState(initialValues.azureAppSecret);
  // const [userFilterString, setUserFilterString] = useState(initialValues.userFilterString);

  const resetStateValues = () => {
    const cleanValues = azureTenantRecord ? { ...initialValues, ...azureTenantRecord } : initialValues;
    setName(cleanValues.name);
    setAzureTenantId(cleanValues.azureTenantId);
    setAzureAppId(cleanValues.azureAppId);
    setAzureAppSecret(cleanValues.azureAppSecret);
    // setUserFilterString(cleanValues.userFilterString);
  };
  const [azureTenantRecord, setAzureTenantRecord] = useState(azureTenantModelProp);
  useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getAzureTenant,
              variables: {
                id: idProp,
              },
            })
          )?.data?.getAzureTenant
        : azureTenantModelProp;
      setAzureTenantRecord(record);
    };
    queryData();
  }, [idProp, azureTenantModelProp]);
  useEffect(resetStateValues, [azureTenantRecord]);

  const submit = async () => {
    let modelFields = {
      name,
      azureTenantId,
      azureAppId,
      azureAppSecret,
    };

    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === "string" && value === "") {
          modelFields[key] = null;
        }
      });
      await client.graphql({
        query: updateAzureTenant,
        variables: {
          input: {
            id: azureTenantRecord.id,
            ...modelFields,
          },
        },
      });
      if (props.onSuccess) {
        props.onSuccess(modelFields);
      }
    } catch (err) {
      if (props.onError) {
        props.onError(modelFields, err.message);
      }
    }
  };

  return (
    <div className={props.className} style={{ minWidth: 400 }}>
      <div style={{ fontSize: FontSizes.size24, marginBottom: 20 }}>Edit Azure Tenant</div>
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField label="Display Name" value={name} onChange={(e, v) => setName(v)} />
        <TextField label="Azure Tenant ID" value={azureTenantId} onChange={(e, v) => setAzureTenantId(v)} />
        <TextField label="Azure App Registration ID" value={azureAppId} onChange={(e, v) => setAzureAppId(v)} />
        <TextField
          label="Azure App Secret"
          value={azureAppSecret}
          onChange={(e, v) => setAzureAppSecret(v)}
          type="password"
          canRevealPassword={true}
        />
        {/* <TextField
          label="User Filter Query String"
          value={userFilterString}
          placeholder="endsWith(mail,'.au') and mobilePhone ne null"
          onChange={(e, v) => setUserFilterString(v)}
        /> */}
        <Stack horizontal={true} horizontalAlign="center" tokens={{ childrenGap: 10, padding: 20 }}>
          <DefaultButton text="Cancel" onClick={props.onCancel} />
          <PrimaryButton text="Save" onClick={submit} />
        </Stack>
      </Stack>
    </div>
  );
}
