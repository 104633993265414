import * as React from "react";
import { DefaultButton, PrimaryButton, FontSizes, Stack, TextField, Checkbox } from "@fluentui/react";
import { generateClient } from "aws-amplify/api";
import { createMessageTemplate } from "../graphql/mutations";
import { TextAreaField } from "@aws-amplify/ui-react";

export default function (props) {
  const [templateName, setTemplateName] = React.useState("");
  const [templateBody, setTemplateBody] = React.useState("");

  const client = React.useMemo(() => {
    return generateClient();
  }, []);

  const submit = async () => {
    let modelFields = {
      templateName: templateName.trim(),
      templateBody: templateBody.trim(),
    };

    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === "string" && value === "") {
          modelFields[key] = null;
        }
      });
      const newMessageTemplate = await client.graphql({
        query: createMessageTemplate,
        variables: { input: modelFields },
      });
      if (props.onSuccess) {
        props.onSuccess(newMessageTemplate);
      }
    } catch (err) {
      console.error(err);
      if (props.onError) {
        props.onError(modelFields, err.message);
      }
    }
  };

  return (
    <div className={props.className} style={{ minWidth: 400 }}>
      <div style={{ fontSize: FontSizes.size24, marginBottom: 20 }}>Add Message Template</div>
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField label="Template Name" value={templateName} onChange={(e, v) => setTemplateName(v)} />
        <div style={{ width: 600 }}>
          <TextField label="Message" value={templateBody} onChange={(e, v) => setTemplateBody(v)} multiline rows={8} />
        </div>
        <Stack horizontal={true} horizontalAlign="center" tokens={{ childrenGap: 10, padding: 20 }}>
          <DefaultButton text="Cancel" onClick={props.onCancel} />
          <PrimaryButton text="Submit" onClick={submit} />
        </Stack>
      </Stack>
    </div>
  );
}
