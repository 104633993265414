import { useState, useMemo, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { DefaultButton, PrimaryButton, FontSizes, Stack, TextField, Dropdown, Checkbox } from "@fluentui/react";
import { updateContactGroup } from "../graphql/mutations";
import { getContactGroup, listAzureTenants } from "../graphql/queries";

export default function (props) {
  const { contactGroupId: idProp, contactGroup: contactGroupModelProp } = props;
  const initialValues = {
    azureId: "",
    name: "",
    azureTenantId: null,
    isFavorite: false,
  };
  const client = useMemo(() => {
    return generateClient();
  }, []);

  const [azureId, setAzureId] = useState(initialValues.azureId);
  const [name, setName] = useState(initialValues.name);
  const [azureTenantId, setAzureTenantId] = useState(initialValues.azureTenantId);
  const [isFavorite, setIsFavorite] = useState(initialValues.isFavorite);

  const [azureTenants, setAzureTenants] = useState();
  const [contactGroupRecord, setContactGroupRecord] = useState(contactGroupModelProp);

  const resetStateValues = () => {
    const cleanValues = contactGroupRecord ? { ...initialValues, ...contactGroupRecord } : initialValues;
    setAzureId(cleanValues.azureId);
    setAzureTenantId(cleanValues.azureTenantId);
    setName(cleanValues.name);
    setIsFavorite(cleanValues.isFavorite);
  };

  const fetchTenants = async () => {
    const fetched = await client.graphql({
      query: listAzureTenants,
    });
    setAzureTenants(fetched.data.listAzureTenants.items);
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getContactGroup,
              variables: {
                id: idProp,
              },
            })
          )?.data?.getContactGroup
        : contactGroupModelProp;
      setContactGroupRecord(record);
    };
    queryData();
  }, [idProp, contactGroupModelProp]);
  useEffect(resetStateValues, [contactGroupRecord]);

  const tenantOptions = useMemo(() => {
    if (azureTenants && Array.isArray(azureTenants)) {
      return azureTenants.map((a) => {
        return { key: a.id, text: a.name };
      });
    }
    return [{ key: 0, text: "Fetching Tenants...", disabled: true }];
  }, [azureTenants]);

  const submit = async () => {
    let modelFields = {
      name,
      azureTenantId,
      azureId,
      isFavorite,
      syncStatus: "Not Synced",
    };

    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === "string" && value === "") {
          modelFields[key] = null;
        }
      });
      await client.graphql({
        query: updateContactGroup,
        variables: {
          input: {
            id: contactGroupRecord.id,
            ...modelFields,
          },
        },
      });
      if (props.onSuccess) {
        props.onSuccess(modelFields);
      }
    } catch (err) {
      console.error(err);
      if (props.onError) {
        props.onError(modelFields, err.message);
      }
    }
  };

  return (
    <div className={props.className} style={{ minWidth: 400 }}>
      <div style={{ fontSize: FontSizes.size24, marginBottom: 20 }}>Edit Contact Group</div>
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField label="Display Name" value={name} placeholder="eg: RDO Branch Managers" onChange={(e, v) => setName(v)} />
        <Checkbox
          label="Set as Favorite Contact Group"
          checked={isFavorite}
          onChange={(e, checked) => {
            setIsFavorite(!!checked);
          }}
        />
        <Dropdown
          label="Azure Tenant"
          options={tenantOptions}
          multiSelect={false}
          selectedKey={azureTenantId}
          onChange={(e, i) => setAzureTenantId(i.key)}
        />
        <TextField
          label="Azure Group Object ID"
          value={azureId}
          placeholder="00000000-0000-0000-0000-000000000000"
          onChange={(e, v) => setAzureId(v.trim())}
        />
        <Stack horizontal={true} horizontalAlign="center" tokens={{ childrenGap: 10, padding: 20 }}>
          <DefaultButton text="Cancel" onClick={props.onCancel} />
          <PrimaryButton text="Submit" onClick={submit} />
        </Stack>
      </Stack>
    </div>
  );
}
