import * as React from "react";
import { DefaultButton, PrimaryButton, FontSizes, Stack, TextField, Checkbox } from "@fluentui/react";
import { generateClient } from "aws-amplify/api";
import { createAzureTenant } from "../graphql/mutations";

export default function (props) {
  const [name, setName] = React.useState("");
  const [azureTenantId, setAzureTenantId] = React.useState("");
  const [azureAppId, setAzureAppId] = React.useState("");
  const [azureAppSecret, setAzureAppSecret] = React.useState("");
  // const [userFilterString, setUserFilterString] = React.useState("endsWith(mail,'.au') and mobilePhone ne null");

  const client = React.useMemo(() => {
    return generateClient();
  }, []);

  const submit = async () => {
    let modelFields = {
      name,
      azureTenantId,
      azureAppId,
      azureAppSecret,
      syncStatus: "Scheduled",
    };

    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === "string" && value === "") {
          modelFields[key] = null;
        }
      });
      const newTenant = await client.graphql({
        query: createAzureTenant,
        variables: { input: modelFields },
      });
      if (props.onSuccess) {
        props.onSuccess(newTenant);
      }
    } catch (err) {
      console.error(err);
      if (props.onError) {
        props.onError(modelFields, err.message);
      }
    }
  };

  return (
    <div className={props.className} style={{ minWidth: 400 }}>
      <div style={{ fontSize: FontSizes.size24, marginBottom: 20 }}>Add Azure Tenant</div>
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField label="Display Name" value={name} onChange={(e, v) => setName(v)} />
        <TextField label="Azure Tenant ID" value={azureTenantId} onChange={(e, v) => setAzureTenantId(v)} />
        <TextField label="Azure App Registration ID" value={azureAppId} onChange={(e, v) => setAzureAppId(v)} />
        <TextField label="Azure App Secret" value={azureAppSecret} onChange={(e, v) => setAzureAppSecret(v)} />
        {/* <TextField
          label="User Filter Query String"
          value={userFilterString}
          placeholder="endsWith(mail,'.au') and mobilePhone ne null"
          onChange={(e, v) => setUserFilterString(v)}
        /> */}
        <Stack horizontal={true} horizontalAlign="center" tokens={{ childrenGap: 10, padding: 20 }}>
          <DefaultButton text="Cancel" onClick={props.onCancel} />
          <PrimaryButton text="Submit" onClick={submit} />
        </Stack>
      </Stack>
    </div>
  );
}
