import { FontSizes } from "@fluentui/theme";
import { Modal } from "@fluentui/react";
import { generateClient } from "aws-amplify/api";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  CommandBar,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  DefaultButton,
  ProgressIndicator,
} from "@fluentui/react";
import { useEffect, useState, useMemo } from "react";
import { getSendFromNumber, listSendFromNumbers } from "../graphql/queries";
import FromNumberCreateForm from "../forms/FromNumberCreateForm";
import FromNumberEditForm from "../forms/FromNumberEditForm";
import { deleteSendFromNumber } from "../graphql/mutations";

const FromNumbers = (props) => {
  const [modal, setModal] = useState();
  const [fromNumbers, setFromNumbers] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedFromNumbers, setSelectedFromNumbers] = useState([]);

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchFromNumbers = async () => {
    const fetched = await client.graphql({
      query: listSendFromNumbers,
      variables: { limit: 99999 },
    });
    setFromNumbers(fetched.data.listSendFromNumbers.items);
  };

  useEffect(() => {
    fetchFromNumbers();
  }, []);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedFromNumbers(selection.getSelection());
    },
    selectionMode: SelectionMode.single,
    getKey: (item) => item.id,
  });

  const deleteNumber = async () => {
    try {
      setShowConfirmDelete(false);
      const toDelete = await client.graphql({
        query: getSendFromNumber,
        variables: {
          id: selectedFromNumbers[0].id,
        },
      });
      const idToDelete = toDelete?.data?.getSendFromNumber?.id;
      await client.graphql({
        query: deleteSendFromNumber,
        variables: {
          input: {
            id: idToDelete,
          },
        },
      });
    } catch (error) {
      console.error("Failed to delete number", error.message, error);
    } finally {
      setSelectedFromNumbers([]);
      fetchFromNumbers();
    }
  };

  const columns = [{ key: "number", name: "Number", fieldName: "number" }];

  return (
    <>
      <Dialog hidden={!showConfirmDelete} dialogContentProps={{ type: DialogType.normal, title: "Confirm Deletion" }}>
        Are you sure you want to number{" "}
        <strong>{Array.isArray(selectedFromNumbers) && selectedFromNumbers.length ? selectedFromNumbers[0].number : ""}</strong>?
        <br />
        <br />
        <DialogFooter>
          <PrimaryButton onClick={deleteNumber}>Delete</PrimaryButton>
          <DefaultButton onClick={() => setShowConfirmDelete(false)}>Cancel</DefaultButton>
        </DialogFooter>
      </Dialog>

      <Modal isOpen={modal == "Add"} onDismiss={() => setModal(null)}>
        <FromNumberCreateForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchFromNumbers();
            setModal(null);
          }}
        />
      </Modal>
      <Modal isOpen={modal == "Edit"} onDismiss={() => setModal(null)}>
        <FromNumberEditForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchFromNumbers();
            setModal(null);
          }}
          fromNumber={selectedFromNumbers?.length ? selectedFromNumbers[0] : null}
          fromNumberId={selectedFromNumbers?.length ? selectedFromNumbers[0]?.id : null}
        />
      </Modal>

      <CommandBar
        items={[
          {
            key: "new",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => setModal("Add"),
          },
          {
            key: "delete",
            text: "Delete",
            iconProps: { iconName: "Delete" },
            disabled: !selectedFromNumbers || !selectedFromNumbers.length,
            onClick: () => {
              setShowConfirmDelete(true);
            },
          },
        ]}
      />
      <DetailsList
        columns={columns}
        items={fromNumbers.sort((a, b) => (a.number > b.number ? 1 : -1))}
        selection={selection}
        selectionMode={SelectionMode.single}
        layoutMode={DetailsListLayoutMode.justified}
        setKey="id"
        constrainMode={1}
        onItemInvoked={() => {
          setModal("Edit");
        }}
      ></DetailsList>
    </>
  );
};

export default FromNumbers;
