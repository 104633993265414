import React from "react";
import { BrowserRouter, Routes, Route, NavLink, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Authenticator, Button, View, useTheme, Image, Heading, ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { signInWithRedirect } from "aws-amplify/auth";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { Icon, Stack, StackItem } from "@fluentui/react";
import config from "./aws-exports";
import Contacts from "./pages/Contacts";
import SentMessages from "./pages/SentMessages";
import SendSMS from "./pages/SendSMS";
import ContactGroup from "./pages/ContactGroups";
import AzureTenant from "./pages/AzureTenant";
import MessageTemplates from "./pages/MessageTemplates";
import FromNumbers from "./pages/FromNumbers";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import NavBar from "./navigation/NavBar";

Amplify.configure(config, {
  Storage: {
    S3: {
      // configures default access level
      defaultAccessLevel: "private",
    },
  },
});

initializeIcons();

function App() {
  const { tokens } = useTheme();
  const theme = {
    name: "Auth Example Theme",
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay["10"]}`,
            borderWidth: "0",
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral["100"],
          },
          link: {
            color: tokens.colors.purple["80"],
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.purple["60"]}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral["80"],
            _active: {
              borderColor: tokens.colors.neutral["100"],
              color: tokens.colors.purple["100"],
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        hideSignUp={true}
        components={{
          Header() {
            const { tokens } = useTheme();

            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Image alt="Amplify logo" src="/rdo-logo.jpg" />
              </View>
            );
          },
          SignIn: {
            Header() {
              const { tokens } = useTheme();

              return (
                <>
                  <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4}>
                    Sign in using your RDO account
                  </Heading>
                  <View textAlign="center" padding={tokens.space.large}>
                    <Button
                      className="amplify-button amplify-field-group__control amplify-button--primary amplify-button--fullwidth"
                      onClick={() => {
                        signInWithRedirect({ provider: { custom: "AzureSSO" } });
                      }}
                    >
                      Sign in using Azure SSO
                    </Button>
                  </View>

                  <Heading textAlign="left" style={{ padding: "64px 32px 0 32px" }} level={4}>
                    Or sign in with a local user account
                  </Heading>
                </>
              );
            },
          },
        }}
      >
        {({ signOut, user }) => (
          <BrowserRouter>
            <NavBar signOut={signOut} />
            <main data-is-scrollable>
              <Routes>
                <Route path="/" element={<Navigate to="/sms" replace />} />
                <Route path="/sms" element={<SendSMS user={user} />}></Route>
                <Route path="/azuretenants" element={<AzureTenant />}></Route>
                <Route path="/contactgroups" element={<ContactGroup />}></Route>
                <Route path="/contacts" element={<Contacts />}></Route>
                <Route path="/history" element={<SentMessages />}></Route>
                <Route path="/templates" element={<MessageTemplates />}></Route>
                <Route path="/fromNumbers" element={<FromNumbers />}></Route>
              </Routes>
            </main>
          </BrowserRouter>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
