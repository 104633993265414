/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://t24n3ad63fhupdzc5jxoyt3ggy.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-k4y7kf2wpjcyxes7o2r6rdroei",
    "aws_cognito_identity_pool_id": "ap-southeast-2:20111913-3e5b-4ad4-bcb4-12bd66fd70cd",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_NlYclFNAV",
    "aws_user_pools_web_client_id": "3s0m7geboddgdsp3onha8jp9tk",
    "oauth": {
        "domain": "rdo-sms-notify-production.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://sms-notify.rdo.com.au/",
        "redirectSignOut": "https://sms-notify.rdo.com.au/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "rdoitsmsalert-storage182819-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
