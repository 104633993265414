import { useState, useEffect, useMemo, useRef } from "react";
import {
  TextField,
  Dropdown,
  DefaultButton,
  PrimaryButton,
  ChoiceGroup,
  FontSizes,
  MessageBar,
  MessageBarType,
  Stack,
  Spinner,
  SpinnerSize,
  Dialog,
  DropdownMenuItemType,
  Icon,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { useHistory } from "react-router-dom";
import { uploadData, getUrl } from "aws-amplify/storage";
import { v4 as uuidv4 } from "uuid";
import { listContactGroups, listMessageTemplates, listSendFromNumbers } from "../graphql/queries";
import * as Mutations from "../graphql/mutations";

const MAX_ATTACHMENT_SIZE = 3000000;

function formatFileSize(bytes) {
  if (bytes >= 1073741824) {
    // 1 Gigabyte = 1073741824 Bytes
    return (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    // 1 Megabyte = 1048576 Bytes
    return (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    // 1 Kilobyte = 1024 Bytes
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return bytes + " B"; // Bytes
  }
}

const SendSMS = (props) => {
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState();
  const [contactGroups, setContactGroups] = useState([]);
  const [sendFromNumbers, setSendFromNumbers] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [displayBarMessage, setDisplayBarMessage] = useState();
  const [displayBarMessageType, setDisplayBarMessageType] = useState();
  const [isSending, setIsSending] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [attachedFile, setAttachedFile] = useState();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchGroups = async () => {
    const gs = await client.graphql({
      query: listContactGroups,
      variables: { limit: 99999 },
    });
    setContactGroups(gs?.data?.listContactGroups?.items);
  };

  const fetchTemplates = async () => {
    const res = await client.graphql({
      query: listMessageTemplates,
      variables: { limit: 99999 },
    });
    setMessageTemplates(res?.data?.listMessageTemplates?.items);
  };

  const fetchSenderNumbers = async () => {
    const res = await client.graphql({
      query: listSendFromNumbers,
      variables: { limit: 99999 },
    });
    setSendFromNumbers(res?.data?.listSendFromNumbers?.items);
  };

  useEffect(() => {
    fetchGroups();
    fetchTemplates();
    fetchSenderNumbers();
  }, []);

  const templateOptions = Array.isArray(messageTemplates)
    ? [
        { key: "none", text: "No Template" },
        ...messageTemplates.map((t) => {
          return { key: t.id, text: t.templateName };
        }),
      ]
    : [];

  const buildContactGroupOptions = () => {
    let options = [];
    const faves = contactGroups.filter((c) => c.isFavorite).sort((a, b) => (a.name > b.name ? 1 : -1));
    if (faves.length) {
      options = [
        { key: "HeaderFav", text: "Favorite Contact Groups", itemType: DropdownMenuItemType.Header },
        ...faves.map((f) => {
          return { key: f.id, text: f.name };
        }),
      ];
    }
    const rest = contactGroups.filter((c) => !c.isFavorite).sort((a, b) => (a.name > b.name ? 1 : -1));
    const otherOptions = faves.length
      ? [
          { key: "HeaderOther", text: "Other Contact Groups", itemType: DropdownMenuItemType.Header },
          ...rest.map((g) => {
            return { key: g.id, text: g.name };
          }),
        ]
      : [
          ...rest.map((g) => {
            return { key: g.id, text: g.name };
          }),
        ];
    return [...options, ...otherOptions];
  };

  const contactGroupOptions = buildContactGroupOptions() ?? [];

  const onContactGroupChange = (e, item) => {
    if (item) {
      setSelectedGroupIds(item.selected ? [...selectedGroupIds, item.key] : selectedGroupIds.filter((key) => key !== item.key));
    }
  };

  const doSendSMS = async () => {
    try {
      setIsSending(true);
      setDisplayBarMessage(null);
      setDisplayBarMessageType(null);

      let variables = { messageBody: message?.trim(), contactGroups: selectedGroupIds, fileKey: null, from };
      if (attachedFile?.name) {
        const fileKey = `${uuidv4()}__${attachedFile.name}`;
        const result = await uploadData({
          key: fileKey,
          data: attachedFile,
          options: {
            contentType: attachedFile.type, // contentType is optional
          },
        }).result;
        console.log(result);
        if (result) {
          variables.fileKey = result.key;
          variables.fileName = attachedFile.name;
        }
      }

      console.log("sending SMS with these vars:", variables);
      const messageResponse = await client.graphql({
        query: Mutations.sendSMS,
        variables,
      });
      console.log(messageResponse);
      if (messageResponse.errors?.length) {
        setDisplayBarMessage(messageResponse.errors[0].message);
        setDisplayBarMessageType(MessageBarType.error);
      } else {
        setDisplayBarMessage("Successfully sent SMS message");
        setDisplayBarMessageType(MessageBarType.success);
        setMessage("");
        setSelectedGroupIds([]);
        navigate(`/history?id=${messageResponse?.data?.sendSMS?.id}`);
      }
    } catch (error) {
      console.log(error);
      setDisplayBarMessage(error?.errors?.length ? error.errors[0].message : "Failed to send SMS message");
      setDisplayBarMessageType(MessageBarType.error);
    } finally {
      setIsSending(false);
    }
  };

  const handleAttachmentButtonClick = () => {
    if (attachedFile) {
      setAttachedFile(null);
    } else {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    if (attachedFile) {
      return null;
    }
    const file = event.target.files[0];
    setAttachedFile(file);
  };

  const getFileIcon = () => {
    if (!attachedFile?.name) return null;

    const lastIndex = attachedFile.name.lastIndexOf(".");
    if (lastIndex === -1) return null;

    const fileType = attachedFile.name.substring(lastIndex + 1).toLowerCase();

    if (fileType == "pdf") {
      return "PDF";
    }
    if (["png", "jpg", "jpeg", "bmp"].includes(fileType)) {
      return "FileImage";
    }
    if (["doc", "docx"].includes(fileType)) {
      return "WordDocument";
    }
    if (["xls", "xlsx"].includes(fileType)) {
      return "ExcelDocument";
    }
    return "TextDocument";
  };

  return (
    <div className="SMSPageContainer">
      <div style={{ fontSize: FontSizes.size42, marginBottom: 20 }}>Send Message</div>
      <Dropdown
        label="Send to Contact Groups"
        multiSelect={true}
        options={contactGroupOptions}
        selectedKeys={selectedGroupIds}
        onChange={onContactGroupChange}
        placeholder="Select Contact Groups to send this message to"
        style={{ marginBottom: 20 }}
      />
      <Dropdown
        label="Number to Send From"
        options={sendFromNumbers
          .map((x) => {
            return { key: x.number, text: x.number };
          })
          .sort((a, b) => (a.key > b.key ? 1 : -1))}
        style={{ marginBottom: 20 }}
        onChange={(e, v) => {
          setFrom(v?.key);
        }}
        selectedKey={from}
      />
      {from?.match(/04\d{8}/) && (
        <MessageBar messageBarType={MessageBarType.info}>
          Be sure to identity yourself as RDO Equipment so recipients know who is sending this message.
        </MessageBar>
      )}
      <Dropdown
        label="Message Template"
        options={templateOptions}
        selectedKey={selectedTemplateId}
        onChange={(e, v) => {
          setSelectedTemplateId(v.key == "none" ? null : v.key);
          const message = messageTemplates.find((t) => t.id == v.key);
          setMessage(message?.templateBody ?? "");
        }}
        placeholder="Select a message template"
        style={{ marginBottom: 20 }}
      />
      <TextField
        label="Message"
        multiline={true}
        rows={15}
        value={message}
        onChange={(e, v) => {
          const selectedMessageTemplate = selectedTemplateId ? messageTemplates.find((t) => t.id == selectedTemplateId) : null;
          if (selectedMessageTemplate && v.trim() !== selectedMessageTemplate.templateBody) setSelectedTemplateId(null);
          setMessage(v);
        }}
      ></TextField>
      <div style={{ marginTop: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 30 }}>
          <DefaultButton text={attachedFile ? "Remove attachment" : "Add Attatchment"} onClick={handleAttachmentButtonClick} />
          <input type="file" style={{ display: "none" }} onChange={handleFileChange} ref={fileInputRef} />
          {attachedFile && (
            <div
              style={{
                fontWeight: "bold",
                lineHeight: "30px",
                padding: "0 30px",
                backgroundColor: attachedFile.size < MAX_ATTACHMENT_SIZE ? "#00ffaa8c" : "rgb(255 158 160)",
              }}
            >
              <Stack horizontal>
                <Icon iconName={getFileIcon()} style={{ marginRight: 10, fontSize: "1.8em" }} />
                <span>
                  {attachedFile.name} - {formatFileSize(attachedFile.size)}
                </span>
              </Stack>
            </div>
          )}
        </Stack>
      </div>
      {attachedFile && attachedFile.size >= MAX_ATTACHMENT_SIZE && (
        <div style={{ marginTop: 20 }}>
          <MessageBar messageBarType={MessageBarType.error}>Maximum attachment size is {formatFileSize(MAX_ATTACHMENT_SIZE)}</MessageBar>
        </div>
      )}
      <div style={{ marginTop: 60 }}>
        <Stack tokens={{ childrenGap: 20 }}>
          <div style={{ textAlign: "center" }}>
            <PrimaryButton
              disabled={
                (!message && !attachedFile) ||
                !selectedGroupIds ||
                selectedGroupIds.length == 0 ||
                isSending ||
                (attachedFile && attachedFile.size > MAX_ATTACHMENT_SIZE)
              }
              onClick={doSendSMS}
            >
              {isSending ? (
                <Spinner size={SpinnerSize.medium} />
              ) : (
                <>
                  <Icon iconName="MessageFill" style={{ marginRight: 15 }} />
                  {`Send ${attachedFile ? "MMS" : "SMS"}`}
                </>
              )}
            </PrimaryButton>
          </div>
          {displayBarMessage && displayBarMessageType && (
            <MessageBar messageBarType={displayBarMessageType}>{displayBarMessage}</MessageBar>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default SendSMS;
