import { FontSizes } from "@fluentui/theme";
import { Modal } from "@fluentui/react";
import { generateClient } from "aws-amplify/api";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, CommandBar } from "@fluentui/react";
import { useEffect, useState, useMemo } from "react";
import AzureTenantEditForm from "../forms/AzureTenantEditForm";
import { listMessageTemplates, getMessageTemplate } from "../graphql/queries";
import { deleteMessageTemplate } from "../graphql/mutations";
import MessageTemplateCreateForm from "../forms/MessageTemplateCreateForm";
import MessageTemplateEditForm from "../forms/MessageTemplateEditForm";

const MessageTemplates = (props) => {
  const [modal, setModal] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [templates, setTemplates] = useState([]);

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchTemplates = async () => {
    const fetched = await client.graphql({
      query: listMessageTemplates,
    });
    setTemplates(fetched.data.listMessageTemplates.items);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedTemplate(selection.getSelection());
    },
    selectionMode: SelectionMode.single,
    getKey: (item) => item.id,
  });

  const columns = [
    { key: "templateName", name: "Template Name", fieldName: "templateName", minWidth: 100, maxWidth: 600, isResizable: true },
    { key: "templateBody", name: "Template Body", fieldName: "templateBody", minWidth: 100, maxWidth: 600, isResizable: true },
  ];

  const deleteTenant = async () => {
    const toDelete = await client.graphql({
      query: getMessageTemplate,
      variables: {
        id: selectedTemplate[0].id,
      },
    });
    const idToDelete = toDelete?.data?.getMessageTemplate?.id;
    if (idToDelete) {
      await client.graphql({
        query: deleteMessageTemplate,
        variables: {
          input: {
            id: idToDelete,
          },
        },
      });
    } else {
      console.log(`Couldnt find tenant to delete`);
    }
    setSelectedTemplate([]);
    fetchTemplates();
  };

  return (
    <>
      <div style={{ fontSize: FontSizes.size42 }}>Message Templates</div>
      <br />

      <Modal isOpen={modal == "Add"} onDismiss={() => setModal(null)}>
        <MessageTemplateCreateForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchTemplates();
            setModal(null);
          }}
        />
      </Modal>
      <Modal isOpen={modal == "Edit"} onDismiss={() => setModal(null)}>
        <MessageTemplateEditForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchTemplates();
            setModal(null);
          }}
          messageTemplate={selectedTemplate?.length ? selectedTemplate[0] : null}
          messageTemplateId={selectedTemplate?.length ? selectedTemplate[0]?.id : null}
        />
      </Modal>

      <CommandBar
        items={[
          {
            key: "new",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => setModal("Add"),
          },
          {
            key: "delete",
            text: "Delete",
            iconProps: { iconName: "Delete" },
            disabled: !selectedTemplate || !selectedTemplate.length,
            onClick: deleteTenant,
          },
        ]}
      />
      <DetailsList
        columns={columns}
        items={templates}
        selection={selection}
        selectionMode={SelectionMode.single}
        layoutMode={DetailsListLayoutMode.justified}
        setKey="id"
        constrainMode={1}
        onItemInvoked={() => {
          setModal("Edit");
        }}
      ></DetailsList>
    </>
  );
};
export default MessageTemplates;
