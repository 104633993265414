import { useMemo, useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { DefaultButton, PrimaryButton, FontSizes, Stack, TextField, Checkbox } from "@fluentui/react";
import { updateSendFromNumber } from "../graphql/mutations";
import { getSendFromNumber } from "../graphql/queries";

export default function (props) {
  const { fromNumberId: idProp, fromNumber: fromNumberModelProp } = props;

  const initialValues = {
    number: "",
  };
  const client = useMemo(() => {
    return generateClient();
  }, []);

  const [number, setNumber] = useState(initialValues.number);

  const resetStateValues = () => {
    const cleanValues = fromNumberRecord ? { ...initialValues, ...fromNumberRecord } : initialValues;
    setNumber(cleanValues.number);
  };
  const [fromNumberRecord, setFromNumberRecord] = useState(fromNumberModelProp);

  useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getSendFromNumber,
              variables: {
                id: idProp,
              },
            })
          )?.data?.getSendFromNumber
        : fromNumberModelProp;
      setFromNumberRecord(record);
      console.log(record);
    };
    queryData();
  }, [idProp, fromNumberModelProp]);
  useEffect(resetStateValues, [fromNumberRecord]);

  const submit = async () => {
    let modelFields = {
      number,
    };

    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === "string" && value === "") {
          modelFields[key] = null;
        }
      });
      await client.graphql({
        query: updateSendFromNumber,
        variables: {
          input: {
            id: fromNumberRecord.id,
            ...modelFields,
          },
        },
      });
      if (props.onSuccess) {
        props.onSuccess(modelFields);
      }
    } catch (err) {
      if (props.onError) {
        props.onError(modelFields, err.message);
      }
    }
  };

  return (
    <div className={props.className} style={{ minWidth: 400 }}>
      <div style={{ fontSize: FontSizes.size24, marginBottom: 20 }}>Edit Sending Number</div>
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField label="Alphanumeric Number" value={number} onChange={(e, v) => setNumber(v)} />
        <Stack horizontal={true} horizontalAlign="center" tokens={{ childrenGap: 10, padding: 20 }}>
          <DefaultButton text="Cancel" onClick={props.onCancel} />
          <PrimaryButton text="Save" onClick={submit} />
        </Stack>
      </Stack>
    </div>
  );
}
