import { FontSizes } from "@fluentui/theme";
import { Modal } from "@fluentui/react";
import { generateClient } from "aws-amplify/api";
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  CommandBar,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  DefaultButton,
  ProgressIndicator,
} from "@fluentui/react";
import { useEffect, useState, useMemo } from "react";
import AzureTenantCreateForm from "../forms/AzureTenantCreateForm";
import AzureTenantEditForm from "../forms/AzureTenantEditForm";
import { listAzureTenants, getAzureTenant } from "../graphql/queries";
import { deleteAzureTenant, deleteContact, deleteContactGroup, deleteContactGroupContact } from "../graphql/mutations";
import React from "react";

const AzureTenant = (props) => {
  const [modal, setModal] = useState();
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [percentageDeleted, setPercentageDeleted] = useState(null);

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchTenants = async () => {
    const fetched = await client.graphql({
      query: listAzureTenants,
      variables: { limit: 99999 },
    });
    setTenants(fetched.data.listAzureTenants.items);
  };

  useEffect(() => {
    fetchTenants();

    const intervalId = setInterval(() => {
      fetchTenants();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedTenant(selection.getSelection());
    },
    selectionMode: SelectionMode.single,
    getKey: (item) => item.id,
  });

  const columns = [
    { key: "name", name: "Tenant Name", fieldName: "name", minWidth: 100, maxWidth: 600, isResizable: true },
    { key: "azureTenantId", name: "Tenant ID", fieldName: "azureTenantId", minWidth: 300, maxWidth: 600, isResizable: true },
    { key: "syncStatus", name: "Sync Status", fieldName: "syncStatus", minWidth: 150, isResizable: true },
    { key: "lastSyncTimeDisplay", name: "Last Synced", fieldName: "lastSyncTimeDisplay", minWidth: 150, isResizable: true },
  ];

  const deleteTenant = async () => {
    try {
      setShowConfirmDelete(false);
      setPercentageDeleted(0.01);
      let itemsToDeleteCount = 1;
      const toDelete = await client.graphql({
        query: getAzureTenant,
        variables: {
          id: selectedTenant[0].id,
        },
      });
      const idToDelete = toDelete?.data?.getAzureTenant?.id;
      const azureTenantToDelete = toDelete?.data?.getAzureTenant?.azureTenantId;
      if (!idToDelete) {
        console.log(`Couldnt find tenant to delete`);
        return;
      }

      // fetch the contact groups belonging to the tenant to delete
      const contactGroupsToDeleteRes = await client.graphql({
        query: `
      query listContactGroups {
        listContactGroups(limit: 99999, filter: {azureTenantId: {eq: "${idToDelete}"}}) {
          items {
            id
          }
        }
      }
      `,
      });
      const contactGroupsToDelete = contactGroupsToDeleteRes.data.listContactGroups.items;
      itemsToDeleteCount += contactGroupsToDelete.length;

      // fetch the contacts belonging to the tenant to delete
      const contactsToDeleteRes = await client.graphql({
        query: `
      query listContacts {
        listContacts(limit: 99999,filter: {azureTenantId: {eq: "${idToDelete}"}}) {
          items {
            id
          }
        }
      }
      `,
      });
      const contactsToDelete = contactsToDeleteRes.data.listContacts.items;
      console.log(`Will delete ${contactsToDelete.length} contacts`);
      itemsToDeleteCount += contactsToDelete.length;

      // fetch the contact groups contacts mapping belonging to the the contact groups fetched for this tenant
      let contactGroupContactsToDelete = [];
      for (let i = 0; i < contactGroupsToDelete.length; i++) {
        const cg = contactGroupsToDelete[i];
        const contactGroupContactsToDeleteRes = await client.graphql({
          query: `
        query listContactGroupContacts {
          listContactGroupContacts(limit: 99999, filter: {contactGroupId: {eq: "${cg.id}"}}){
            items {
              id
            }
          }
        }
        `,
        });
        contactGroupContactsToDelete = contactGroupContactsToDelete.concat(
          contactGroupContactsToDeleteRes.data.listContactGroupContacts.items
        );
      }
      itemsToDeleteCount += contactGroupContactsToDelete.length;

      let deletedItemCount = 0;
      //delete all the contactgroupcontacts
      for (let i = 0; i < contactGroupContactsToDelete.length; i++) {
        const item = contactGroupContactsToDelete[i];
        await client.graphql({ query: deleteContactGroupContact, variables: { input: { id: item.id } } });
        setPercentageDeleted(++deletedItemCount / itemsToDeleteCount);
      }
      //delete all the contacts
      for (let i = 0; i < contactsToDelete.length; i++) {
        const item = contactsToDelete[i];
        await client.graphql({ query: deleteContact, variables: { input: { id: item.id } } });
        setPercentageDeleted(++deletedItemCount / itemsToDeleteCount);
      }
      //delete all the groups
      for (let i = 0; i < contactGroupsToDelete.length; i++) {
        const item = contactGroupsToDelete[i];
        await client.graphql({ query: deleteContactGroup, variables: { input: { id: item.id } } });
        setPercentageDeleted(++deletedItemCount / itemsToDeleteCount);
      }
      // delete the tenant
      await client.graphql({
        query: deleteAzureTenant,
        variables: {
          input: {
            id: idToDelete,
          },
        },
      });
    } catch (error) {
      console.error("Failed to delete tenant", error.message, error);
    } finally {
      setSelectedTenant([]);
      setPercentageDeleted(null);
      fetchTenants();
    }
  };

  return (
    <>
      <Dialog hidden={!showConfirmDelete} dialogContentProps={{ type: DialogType.normal, title: "Confirm Deletion" }}>
        Are you sure you want to delete azure tenant{" "}
        <strong>{Array.isArray(selectedTenant) && selectedTenant.length ? selectedTenant[0].name : ""}</strong>?
        <br />
        <br />
        This tenant and all its groups and contacts will be permantently deleted.
        <DialogFooter>
          <PrimaryButton onClick={deleteTenant}>Delete</PrimaryButton>
          <DefaultButton onClick={() => setShowConfirmDelete(false)}>Cancel</DefaultButton>
        </DialogFooter>
      </Dialog>

      <Dialog hidden={!percentageDeleted} minWidth={600} dialogContentProps={{ type: DialogType.normal, title: "Deleting Tenant..." }}>
        <br />
        <ProgressIndicator percentComplete={percentageDeleted} />
      </Dialog>
      <div style={{ fontSize: FontSizes.size42 }}>Azure Tenants</div>
      <br />

      <Modal isOpen={modal == "Add"} onDismiss={() => setModal(null)}>
        <AzureTenantCreateForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchTenants();
            setModal(null);
          }}
        />
      </Modal>
      <Modal isOpen={modal == "Edit"} onDismiss={() => setModal(null)}>
        <AzureTenantEditForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchTenants();
            setModal(null);
          }}
          azureTenant={selectedTenant?.length ? selectedTenant[0] : null}
          azureTenantId={selectedTenant?.length ? selectedTenant[0]?.id : null}
        />
      </Modal>

      <CommandBar
        items={[
          {
            key: "new",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => setModal("Add"),
          },
          {
            key: "delete",
            text: "Delete",
            iconProps: { iconName: "Delete" },
            disabled: !selectedTenant || !selectedTenant.length,
            onClick: () => {
              setShowConfirmDelete(true);
            },
          },
        ]}
      />
      <DetailsList
        columns={columns}
        items={tenants.map((t) => {
          return {
            ...t,
            lastSyncTimeDisplay: t.lastSyncTime ? new Date(t.lastSyncTime).toLocaleString() : "Never",
          };
        })}
        selection={selection}
        selectionMode={SelectionMode.single}
        layoutMode={DetailsListLayoutMode.justified}
        setKey="id"
        constrainMode={1}
        onItemInvoked={() => {
          setModal("Edit");
        }}
      ></DetailsList>
    </>
  );
};
export default AzureTenant;
