import { useState, useMemo, useEffect } from "react";
import { DefaultButton, PrimaryButton, FontSizes, Stack, TextField, Dropdown, Checkbox, StackItem } from "@fluentui/react";
import { generateClient } from "aws-amplify/api";
import { createContactGroup } from "../graphql/mutations";
import { listAzureTenants } from "../graphql/queries";

export default function (props) {
  const [name, setName] = useState("");
  const [azureTenantId, setAzureTenantId] = useState();
  const [azureId, setAzureId] = useState("");
  const [azureTenants, setAzureTenants] = useState();
  const [isFavorite, setIsFavorite] = useState();

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchTenants = async () => {
    const fetched = await client.graphql({
      query: listAzureTenants,
    });
    setAzureTenants(fetched.data.listAzureTenants.items);
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  const tenantOptions = useMemo(() => {
    if (azureTenants && Array.isArray(azureTenants)) {
      return azureTenants.map((a) => {
        return { key: a.id, text: a.name };
      });
    }
    return [{ key: 0, text: "Fetching Tenants...", disabled: true }];
  }, [azureTenants]);

  const submit = async () => {
    let modelFields = {
      name,
      azureName: "Will fetch from azure...",
      azureId,
      azureTenantId,
      isFavorite,
      syncStatus: "Not Synced",
    };

    try {
      Object.entries(modelFields).forEach(([key, value]) => {
        if (typeof value === "string" && value === "") {
          modelFields[key] = null;
        }
      });
      const res = await client.graphql({
        query: createContactGroup,
        variables: {
          input: modelFields,
        },
      });
      const newGroupID = res.data.createContactGroup.id;
      // sync the new group with azure
      client.graphql({
        query: `
        mutation MyMutation {
          syncContactGroup(id: "${newGroupID}")
        }
        `,
      });
      if (props.onSuccess) {
        props.onSuccess(modelFields);
      }
    } catch (err) {
      console.error(err);
      if (props.onError) {
        props.onError(modelFields, err.message);
      }
    }
  };

  return (
    <div className={props.className} style={{ minWidth: 400 }}>
      <div style={{ fontSize: FontSizes.size24, marginBottom: 20 }}>Add Contact Group</div>
      <Stack tokens={{ childrenGap: 10 }}>
        <TextField label="Display Name" value={name} placeholder="eg: RDO Branch Managers" onChange={(e, v) => setName(v)} />
        <Checkbox
          label="Set as Favorite Contact Group"
          checked={isFavorite}
          onChange={(e, checked) => {
            setIsFavorite(!!checked);
          }}
        />
        <Dropdown label="Azure Tenant" options={tenantOptions} selectedKey={azureTenantId} onChange={(e, i) => setAzureTenantId(i.key)} />
        <TextField
          label="Azure Group Object ID"
          value={azureId}
          placeholder="00000000-0000-0000-0000-000000000000"
          onChange={(e, v) => setAzureId(v.trim())}
        />
        <Stack horizontal={true} horizontalAlign="center" tokens={{ childrenGap: 10, padding: 20 }}>
          <DefaultButton text="Cancel" onClick={props.onCancel} />
          <PrimaryButton text="Submit" onClick={submit} />
        </Stack>
      </Stack>
    </div>
  );
}
