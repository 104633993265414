/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShortUrl = /* GraphQL */ `
  mutation CreateShortUrl(
    $input: CreateShortUrlInput!
    $condition: ModelShortUrlConditionInput
  ) {
    createShortUrl(input: $input, condition: $condition) {
      id
      shortCode
      s3ObjectFullKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShortUrl = /* GraphQL */ `
  mutation UpdateShortUrl(
    $input: UpdateShortUrlInput!
    $condition: ModelShortUrlConditionInput
  ) {
    updateShortUrl(input: $input, condition: $condition) {
      id
      shortCode
      s3ObjectFullKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShortUrl = /* GraphQL */ `
  mutation DeleteShortUrl(
    $input: DeleteShortUrlInput!
    $condition: ModelShortUrlConditionInput
  ) {
    deleteShortUrl(input: $input, condition: $condition) {
      id
      shortCode
      s3ObjectFullKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSMSMessage = /* GraphQL */ `
  mutation CreateSMSMessage(
    $input: CreateSMSMessageInput!
    $condition: ModelSMSMessageConditionInput
  ) {
    createSMSMessage(input: $input, condition: $condition) {
      id
      senderName
      from
      message
      fileKey
      fileName
      messageType
      recipients {
        mobileNumber
        name
        delivered
        __typename
      }
      contactGroupRecipients {
        name
        azureId
        azureName
        azureTenantName
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSMSMessage = /* GraphQL */ `
  mutation UpdateSMSMessage(
    $input: UpdateSMSMessageInput!
    $condition: ModelSMSMessageConditionInput
  ) {
    updateSMSMessage(input: $input, condition: $condition) {
      id
      senderName
      from
      message
      fileKey
      fileName
      messageType
      recipients {
        mobileNumber
        name
        delivered
        __typename
      }
      contactGroupRecipients {
        name
        azureId
        azureName
        azureTenantName
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSMSMessage = /* GraphQL */ `
  mutation DeleteSMSMessage(
    $input: DeleteSMSMessageInput!
    $condition: ModelSMSMessageConditionInput
  ) {
    deleteSMSMessage(input: $input, condition: $condition) {
      id
      senderName
      from
      message
      fileKey
      fileName
      messageType
      recipients {
        mobileNumber
        name
        delivered
        __typename
      }
      contactGroupRecipients {
        name
        azureId
        azureName
        azureTenantName
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessageTemplate = /* GraphQL */ `
  mutation CreateMessageTemplate(
    $input: CreateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    createMessageTemplate(input: $input, condition: $condition) {
      id
      templateName
      templateBody
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessageTemplate = /* GraphQL */ `
  mutation UpdateMessageTemplate(
    $input: UpdateMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    updateMessageTemplate(input: $input, condition: $condition) {
      id
      templateName
      templateBody
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessageTemplate = /* GraphQL */ `
  mutation DeleteMessageTemplate(
    $input: DeleteMessageTemplateInput!
    $condition: ModelMessageTemplateConditionInput
  ) {
    deleteMessageTemplate(input: $input, condition: $condition) {
      id
      templateName
      templateBody
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSendFromNumber = /* GraphQL */ `
  mutation CreateSendFromNumber(
    $input: CreateSendFromNumberInput!
    $condition: ModelSendFromNumberConditionInput
  ) {
    createSendFromNumber(input: $input, condition: $condition) {
      id
      number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSendFromNumber = /* GraphQL */ `
  mutation UpdateSendFromNumber(
    $input: UpdateSendFromNumberInput!
    $condition: ModelSendFromNumberConditionInput
  ) {
    updateSendFromNumber(input: $input, condition: $condition) {
      id
      number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSendFromNumber = /* GraphQL */ `
  mutation DeleteSendFromNumber(
    $input: DeleteSendFromNumberInput!
    $condition: ModelSendFromNumberConditionInput
  ) {
    deleteSendFromNumber(input: $input, condition: $condition) {
      id
      number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      azureId
      name
      email
      branch
      mobileNumber
      contactgroups {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      azureId
      name
      email
      branch
      mobileNumber
      contactgroups {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      azureId
      name
      email
      branch
      mobileNumber
      contactgroups {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactGroup = /* GraphQL */ `
  mutation CreateContactGroup(
    $input: CreateContactGroupInput!
    $condition: ModelContactGroupConditionInput
  ) {
    createContactGroup(input: $input, condition: $condition) {
      id
      name
      azureName
      isFavorite
      azureId
      contacts {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactGroup = /* GraphQL */ `
  mutation UpdateContactGroup(
    $input: UpdateContactGroupInput!
    $condition: ModelContactGroupConditionInput
  ) {
    updateContactGroup(input: $input, condition: $condition) {
      id
      name
      azureName
      isFavorite
      azureId
      contacts {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactGroup = /* GraphQL */ `
  mutation DeleteContactGroup(
    $input: DeleteContactGroupInput!
    $condition: ModelContactGroupConditionInput
  ) {
    deleteContactGroup(input: $input, condition: $condition) {
      id
      name
      azureName
      isFavorite
      azureId
      contacts {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAzureTenant = /* GraphQL */ `
  mutation CreateAzureTenant(
    $input: CreateAzureTenantInput!
    $condition: ModelAzureTenantConditionInput
  ) {
    createAzureTenant(input: $input, condition: $condition) {
      id
      name
      azureTenantId
      azureAppId
      azureAppSecret
      Contacts {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contactGroups {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAzureTenant = /* GraphQL */ `
  mutation UpdateAzureTenant(
    $input: UpdateAzureTenantInput!
    $condition: ModelAzureTenantConditionInput
  ) {
    updateAzureTenant(input: $input, condition: $condition) {
      id
      name
      azureTenantId
      azureAppId
      azureAppSecret
      Contacts {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contactGroups {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAzureTenant = /* GraphQL */ `
  mutation DeleteAzureTenant(
    $input: DeleteAzureTenantInput!
    $condition: ModelAzureTenantConditionInput
  ) {
    deleteAzureTenant(input: $input, condition: $condition) {
      id
      name
      azureTenantId
      azureAppId
      azureAppSecret
      Contacts {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contactGroups {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContactGroupContact = /* GraphQL */ `
  mutation CreateContactGroupContact(
    $input: CreateContactGroupContactInput!
    $condition: ModelContactGroupContactConditionInput
  ) {
    createContactGroupContact(input: $input, condition: $condition) {
      id
      contactId
      contactGroupId
      contact {
        id
        azureId
        name
        email
        branch
        mobileNumber
        contactgroups {
          nextToken
          __typename
        }
        azureTenantId
        createdAt
        updatedAt
        __typename
      }
      contactGroup {
        id
        name
        azureName
        isFavorite
        azureId
        contacts {
          nextToken
          __typename
        }
        azureTenantId
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactGroupContact = /* GraphQL */ `
  mutation UpdateContactGroupContact(
    $input: UpdateContactGroupContactInput!
    $condition: ModelContactGroupContactConditionInput
  ) {
    updateContactGroupContact(input: $input, condition: $condition) {
      id
      contactId
      contactGroupId
      contact {
        id
        azureId
        name
        email
        branch
        mobileNumber
        contactgroups {
          nextToken
          __typename
        }
        azureTenantId
        createdAt
        updatedAt
        __typename
      }
      contactGroup {
        id
        name
        azureName
        isFavorite
        azureId
        contacts {
          nextToken
          __typename
        }
        azureTenantId
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactGroupContact = /* GraphQL */ `
  mutation DeleteContactGroupContact(
    $input: DeleteContactGroupContactInput!
    $condition: ModelContactGroupContactConditionInput
  ) {
    deleteContactGroupContact(input: $input, condition: $condition) {
      id
      contactId
      contactGroupId
      contact {
        id
        azureId
        name
        email
        branch
        mobileNumber
        contactgroups {
          nextToken
          __typename
        }
        azureTenantId
        createdAt
        updatedAt
        __typename
      }
      contactGroup {
        id
        name
        azureName
        isFavorite
        azureId
        contacts {
          nextToken
          __typename
        }
        azureTenantId
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const sendSMS = /* GraphQL */ `
  mutation SendSMS(
    $messageBody: String!
    $contactGroups: [ID!]!
    $fileKey: String
    $fileName: String
    $from: String
  ) {
    sendSMS(
      messageBody: $messageBody
      contactGroups: $contactGroups
      fileKey: $fileKey
      fileName: $fileName
      from: $from
    ) {
      id
      senderName
      from
      message
      fileKey
      fileName
      messageType
      recipients {
        mobileNumber
        name
        delivered
        __typename
      }
      contactGroupRecipients {
        name
        azureId
        azureName
        azureTenantName
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const syncContactGroup = /* GraphQL */ `
  mutation SyncContactGroup($id: ID!) {
    syncContactGroup(id: $id)
  }
`;
