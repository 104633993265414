import React from "react";

const TopBar = (props) => {
  const barStyle = {
    position: "fixed", // Fixed position to keep it at the top
    top: 0, // Align to the top of the viewport
    left: 0, // Align to the left of the viewport
    width: "100%", // Full width
    height: "50px", // Desired height of the bar
    backgroundColor: "black", // Black background
    color: "white", // White text/icon for contrast
    display: "flex", // Use flexbox for alignment
    alignItems: "center", // Center items vertically
    paddingLeft: "10px", // Padding from the left edge
    zIndex: 9999,
  };

  const hamburgerIconStyle = {
    cursor: "pointer", // Change cursor to pointer on hover
    userSelect: "none", // Prevent text selection
  };

  return (
    <div style={barStyle}>
      <div style={hamburgerIconStyle} onClick={props.onMenuOpenClick}>
        ☰
      </div>
    </div>
  );
};

export default TopBar;
