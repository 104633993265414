import { FontSizes } from "@fluentui/theme";
import { generateClient } from "aws-amplify/api";
import { DetailsList, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { useEffect, useState, useMemo } from "react";
import { listContacts, listAzureTenants, listContactGroups, listContactGroupContacts } from "../graphql/queries";
import { Dropdown } from "@fluentui/react";

const Contacts = (props) => {
  const [contacts, setContacts] = useState();
  const [tenants, setTenants] = useState([]);
  const [contactGroups, setContactGroups] = useState([]);
  const [contactGroupContacts, setContactGroupContacts] = useState([]);
  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchContacts = async () => {
    const fetchedContacts = await client.graphql({
      query: `
      query MyQuery {
        listContacts(limit: 99999) {
          items {
            id
            email
            name
            mobileNumber
            azureId
            azureTenantId
            branch
            contactgroups {
              items {
                id
                contactGroupId
              }
            }
          }
        }
      }
      
      `,
    });
    setContacts(fetchedContacts?.data?.listContacts?.items);
  };

  const fetchTenants = async () => {
    const fetched = await client.graphql({
      query: listAzureTenants,
      variables: { limit: 99999 },
    });
    setTenants(fetched.data.listAzureTenants.items);
  };

  const fetchContactGroups = async () => {
    const fetched = await client.graphql({
      query: listContactGroups,
      variables: { limit: 99999 },
    });
    setContactGroups(fetched.data.listContactGroups.items);
  };

  const fetchContactGroupsContacts = async () => {
    const fetched = await client.graphql({
      query: listContactGroupContacts,
      variables: { limit: 99999 },
    });
    setContactGroupContacts(
      fetched.data.listContactGroupContacts.items.map((cg) => {
        return { contactId: cg.contactId, contactGroupId: cg.contactGroupId };
      })
    );
  };

  const fetchAll = async () => {
    await fetchContacts();
    await fetchTenants();
    await fetchContactGroups();
    await fetchContactGroupsContacts();
    setLoading(false);
  };

  useEffect(() => {
    fetchAll();

    const intervalId = setInterval(() => {
      fetchAll();
    }, 100000);
    return () => clearInterval(intervalId);
  }, []);

  const contactGroupOptions = useMemo(() => {
    return [
      { key: "ALL", text: "All Contact Groups" },
      ...contactGroups.map((g) => {
        return { key: g.id, text: g.name };
      }),
    ];
  }, [contactGroups]);

  const onDropdownChange = (event, item) => {
    console.log(item);
    if (item?.key == "ALL" && item.selected) {
      setSelectedContactGroups(["ALL"]);
    } else if (item?.key != "ALL" && item.selected) {
      setSelectedContactGroups([...selectedContactGroups.filter((k) => k != "ALL"), item.key]);
    } else if (item) {
      setSelectedContactGroups(
        item.selected ? [...selectedContactGroups, item.key] : selectedContactGroups.filter((key) => key !== item.key)
      );
    }
  };

  const columns = [
    { key: "name", name: "Contact Name", fieldName: "name", minWidth: 150, isResizable: true },
    { key: "mobileNumber", name: "Mobile Number", fieldName: "mobileNumber", minWidth: 200, isResizable: true },
    { key: "branch", name: "Branch", fieldName: "branch", minWidth: 200, isResizable: true },
    { key: "azureTenantName", name: "Azure Tenant", fieldName: "azureTenantName", minWidth: 200, isResizable: true },
  ];

  const items =
    Array.isArray(contacts) && Array.isArray(tenants)
      ? contacts
          .filter((c) => {
            if (selectedContactGroups.length == 0 || selectedContactGroups.includes("ALL")) {
              return true;
            }
            return !!contactGroupContacts.find((cg) => c.id == cg.contactId && selectedContactGroups.includes(cg.contactGroupId));
          })
          .map((c) => {
            const tenant = tenants.find((t) => t.id === c.azureTenantId);
            return {
              ...c,
              azureTenantName: tenant?.name ?? "Tenant",
            };
          })
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      : [];

  return (
    <>
      <div style={{ fontSize: FontSizes.size42 }}>Contacts</div>
      <br />
      <Dropdown
        multiSelect
        options={contactGroupOptions}
        label="Select Contact Groups"
        selectedKeys={selectedContactGroups}
        onChange={onDropdownChange}
        placeholder={loading ? "Loading..." : ""}
      />
      <ShimmeredDetailsList
        columns={columns}
        items={items}
        layoutMode={DetailsListLayoutMode.justified}
        setKey="id"
        enableShimmer={loading}
        constrainMode={1}
        selectionMode={SelectionMode.none}
        onShouldVirtualize={() => false}
      ></ShimmeredDetailsList>
    </>
  );
};
export default Contacts;
