import { FontSizes } from "@fluentui/theme";
import { generateClient } from "aws-amplify/api";
import {
  Icon,
  Modal,
  PrimaryButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogContent,
  DialogType,
  ShimmeredDetailsList,
} from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { useEffect, useState, useMemo } from "react";
import ContactGroupCreateForm from "../forms/ContactGroupCreateForm";
import ContactGroupEditForm from "../forms/ContactGroupEditForm";
import { listContactGroups, getContactGroup, listAzureTenants } from "../graphql/queries";
import { deleteContactGroup, deleteContactGroupContact } from "../graphql/mutations";

const ContactGroup = (props) => {
  const [groups, setGroups] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [modal, setModal] = useState();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(true);

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchGroups = async () => {
    const gs = await client.graphql({
      query: listContactGroups,
      variables: { limit: 99999 },
    });
    setGroups(gs?.data?.listContactGroups?.items);
  };

  const fetchTenants = async () => {
    const fetched = await client.graphql({
      query: listAzureTenants,
      variables: { limit: 99999 },
    });
    setTenants(fetched.data.listAzureTenants.items);
  };

  useEffect(() => {
    const fetchAll = async () => {
      await fetchGroups();
      await fetchTenants();
      setLoading(false);
    };
    fetchAll();

    const intervalId = setInterval(() => {
      fetchGroups();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedGroups(selection.getSelection());
    },
    selectionMode: SelectionMode.single,
    getKey: (item) => item.id,
  });

  const deleteGroup = async () => {
    if (selectedGroups?.length < 1) {
      console.log("You must select a contact group to delete");
    }
    setShowConfirmDelete(false);

    const toDelete = await client.graphql({
      query: getContactGroup,
      variables: {
        id: selectedGroups[0].id,
      },
    });
    const idToDelete = toDelete?.data?.getContactGroup?.id;

    if (idToDelete) {
      const contactGroupContactsToDeleteRes = await client.graphql({
        query: `
        query MyQuery {
          listContactGroupContacts(filter: {contactGroupId: {eq: "${idToDelete}"}}){
            items {
              id
            }
          }
        }
        `,
      });
      const contactGroupContactsToDelete = contactGroupContactsToDeleteRes.data.listContactGroupContacts.items;
      if (Array.isArray(contactGroupContactsToDelete)) {
        for (let index = 0; index < contactGroupContactsToDelete.length; index++) {
          const cg = contactGroupContactsToDelete[index];
          await client.graphql({
            query: deleteContactGroupContact,
            variables: {
              input: {
                id: cg.id,
              },
            },
          });
        }
      }
      await client.graphql({
        query: deleteContactGroup,
        variables: {
          input: {
            id: idToDelete,
          },
        },
      });
    } else {
      console.log(`Couldnt find contact group to delete`);
    }
    setSelectedGroups([]);
    fetchGroups();
  };

  const syncGroup = async () => {
    await client.graphql({
      query: `
      mutation MyMutation {
        updateContactGroup(input: {id: "${selectedGroups[0].id}", syncStatus: "Syncing..."}) {
          id
        }
      }
      `,
    });
    fetchGroups();
    setSelectedGroups([]);

    try {
      client.graphql({
        query: `
        mutation MyMutation {
          syncContactGroup(id: "${selectedGroups[0].id}")
        }
        `,
      });
    } catch {}

    fetchGroups();
  };

  const columns = [
    { key: "nameDisplay", name: "Group Name", fieldName: "nameDisplay", minWidth: 200, isResizable: true },
    { key: "tenantName", name: "Azure Tenant", fieldName: "tenantName", minWidth: 150, isResizable: true },
    { key: "azureName", name: "Azure Group", fieldName: "azureName", minWidth: 200, isResizable: true },
    { key: "lastSyncTimeDisplay", name: "Last Synced", fieldName: "lastSyncTimeDisplay", minWidth: 150, isResizable: true },
    { key: "syncStatus", name: "Sync Status", fieldName: "syncStatus", minWidth: 250, isResizable: true },
  ];

  const items = groups.map((g) => {
    if (Array.isArray(tenants)) {
      const tenant = tenants.find((t) => t.id === g.azureTenantId);
      const tenantName = tenant ? tenant.name : "loading...";
      return {
        ...g,
        tenantName,
        nameDisplay: g.isFavorite ? (
          <>
            {g.name}
            <Icon iconName="FavoriteStarFill" style={{ marginLeft: 10 }} />
          </>
        ) : (
          g.name
        ),
        lastSyncTimeDisplay: g.lastSyncTime ? new Date(g.lastSyncTime).toLocaleString() : "Never",
      };
    }
    return { ...g, tenantName: "loading..." };
  });

  return (
    <>
      <Dialog hidden={!showConfirmDelete} dialogContentProps={{ type: DialogType.normal, title: "Confirm Deletion" }}>
        Are you sure you want to delete contact group{" "}
        <strong>{Array.isArray(selectedGroups) && selectedGroups.length ? selectedGroups[0].name : ""}</strong>?
        <br />
        <br />
        This group will be permantently deleted. You will need to add it again if you want to reverse this change.
        <DialogFooter>
          <PrimaryButton onClick={deleteGroup}>Delete</PrimaryButton>
          <DefaultButton onClick={() => setShowConfirmDelete(false)}>Cancel</DefaultButton>
        </DialogFooter>
      </Dialog>
      <div style={{ fontSize: FontSizes.size42 }}>Contact Groups</div>
      <br />

      <Modal isOpen={modal == "Add"} onDismiss={() => setModal(null)}>
        <ContactGroupCreateForm
          className="modalBase"
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchGroups();
            setModal(null);
          }}
        />
      </Modal>

      <Modal isOpen={modal == "Edit"} onDismiss={() => setModal(null)}>
        <ContactGroupEditForm
          className="modalBase"
          tenants={tenants}
          onCancel={() => setModal(null)}
          onSuccess={(_) => {
            fetchGroups();
            setModal(null);
          }}
          contactGroup={selectedGroups?.length ? selectedGroups[0] : null}
          contactGroupId={selectedGroups?.length ? selectedGroups[0]?.id : null}
        />
      </Modal>

      <CommandBar
        items={[
          {
            key: "new",
            text: "New",
            iconProps: { iconName: "Add" },
            onClick: () => setModal("Add"),
          },
          {
            key: "delete",
            text: "Delete",
            iconProps: { iconName: "Delete" },
            disabled: !selectedGroups || !selectedGroups.length,
            onClick: () => {
              setShowConfirmDelete(true);
            },
          },
          {
            key: "sync",
            text: "Sync",
            iconProps: { iconName: "Sync" },
            disabled: !selectedGroups.length || selectedGroups[0].syncStatus === "Syncing...",
            onClick: syncGroup,
          },
        ]}
      />

      <ShimmeredDetailsList
        columns={columns}
        items={items}
        layoutMode={DetailsListLayoutMode.justified}
        setKey="id"
        enableShimmer={loading}
        constrainMode={1}
        selectionMode={SelectionMode.single}
        selection={selection}
        onItemInvoked={(x) => {
          setModal("Edit");
        }}
      ></ShimmeredDetailsList>
    </>
  );
};
export default ContactGroup;
