import { FontSizes } from "@fluentui/theme";
import { useSearchParams } from "react-router-dom";

import { generateClient } from "aws-amplify/api";
import { DetailsList, DetailsListLayoutMode, SelectionMode, IconButton, Modal, MessageBar, MessageBarType } from "@fluentui/react";
import { useEffect, useState, useMemo } from "react";
import { listSMSMessages } from "../graphql/queries";

const SentMessages = (props) => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [searchParams] = useSearchParams();

  const client = useMemo(() => {
    return generateClient();
  }, []);

  const fetchMessages = async () => {
    const fetched = await client.graphql({
      query: listSMSMessages,
      variables: { limit: 99999 },
    });
    setMessages(fetched.data.listSMSMessages.items);
    return fetched.data.listSMSMessages.items;
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    const fetchAndSetMessage = async () => {
      const messageId = searchParams.get("id");
      if (messageId) {
        const newMessges = await fetchMessages();
        console.log(newMessges);
        console.log(newMessges.find((m) => m.id === messageId));
        setSelectedMessage(newMessges.find((m) => m.id === messageId));
      }
    };
    fetchAndSetMessage();
  }, [searchParams]);

  const columns = [
    {
      key: "createdAtDisplayTime",
      name: "Sent",
      fieldName: "createdAtDisplayTime",
      minWidth: 150,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
    },
    { key: "senderName", name: "From", fieldName: "senderName", minWidth: 150, isResizable: true },
    { key: "recipientGroupDisplay", name: "To", fieldName: "recipientGroupDisplay", minWidth: 200, isResizable: true },
    { key: "messageType", name: "Message Type", fieldName: "messageType", minWidth: 140, isResizable: true },
    { key: "recipientCount", name: "Recipients", fieldName: "recipientCount", minWidth: 100, isResizable: true },
    { key: "message", name: "Message", fieldName: "message", minWidth: 200, isResizable: true },
  ];

  const items = Array.isArray(messages)
    ? messages
        .map((m) => {
          return {
            ...m,
            recipientCount: m.recipients.length,
            recipientGroupDisplay: m.contactGroupRecipients.map((g) => g.name).join(", "),
            createdAtDisplayTime: new Date(m.createdAt).toLocaleString(),
          };
        })
        .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))
    : [];

  return (
    <>
      <div style={{ fontSize: FontSizes.size42 }}>Sent SMS Messages</div>
      <br />

      {selectedMessage && (
        <Modal isOpen={selectedMessage} onDismiss={() => setSelectedMessage(null)}>
          <div style={{ padding: "0 30px 30px 30px", width: 600, display: "flex", flexFlow: "column nowrap", alignItems: "stretch" }}>
            <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center" }}>
              <h2>SMS Message Details</h2>
              <IconButton
                styles={{
                  root: {
                    marginLeft: "auto",
                    marginTop: "4px",
                    marginRight: "2px",
                  },
                  rootHovered: {},
                }}
                iconProps={{ iconName: "Cancel" }}
                onClick={() => setSelectedMessage(null)}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <MessageBar messageBarType={MessageBarType.success}>
                Sent by {selectedMessage.senderName} at {selectedMessage.createdAtDisplayTime}
              </MessageBar>
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Message Type:</span> {selectedMessage.messageType}{" "}
            </div>

            {selectedMessage.fileName && (
              <div>
                <span style={{ fontWeight: "bold" }}>Attached File:</span> <span>{selectedMessage.fileName}</span>
              </div>
            )}
            <br />
            {selectedMessage.message && (
              <>
                <div style={{ fontWeight: "bold" }}>Message:</div>
                <p className="messagedetails-message">{selectedMessage.message}</p>
              </>
            )}

            <div style={{ fontWeight: "bold", marginTop: 30 }}>Sent to Contact Groups:</div>
            <DetailsList
              compact
              items={selectedMessage.contactGroupRecipients}
              columns={[
                { key: "name", name: "Name", fieldName: "name", minWidth: 170, isResizable: true },
                { key: "azureTenantName", name: "Azure Tenant", fieldName: "azureTenantName", minWidth: 120, isResizable: true },
                { key: "azureName", name: "Azure Group Name", fieldName: "azureName", minWidth: 170, isResizable: true },
              ]}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              onShouldVirtualize={() => false}
            />
            <div style={{ fontWeight: "bold", marginTop: 30 }}>Recipients:</div>
            <div style={{ minHeight: 100, maxHeight: 400, overflowY: "auto" }}>
              <DetailsList
                compact
                items={selectedMessage.recipients}
                constrainMode={1}
                columns={[
                  { key: "name", name: "Recipient Name", fieldName: "name", minWidth: 180, isResizable: true },
                  { key: "mobileNumber", name: "Mobile Number", fieldName: "mobileNumber", minWidth: 150, isResizable: true },
                  { key: "delivered", name: "Delivered", fieldName: "delivered", minWidth: 150, isResizable: true },
                ]}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                onShouldVirtualize={() => false}
              />
            </div>
          </div>
        </Modal>
      )}

      <DetailsList
        columns={columns}
        items={items}
        layoutMode={DetailsListLayoutMode.justified}
        setKey="id"
        constrainMode={1}
        selectionMode={SelectionMode.none}
        onItemInvoked={(item) => setSelectedMessage(item)}
        onShouldVirtualize={() => false}
      ></DetailsList>
    </>
  );
};
export default SentMessages;
