import { Icon, Panel, PanelType } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import TopBar from "./TopBar";

const { NavLink } = require("react-router-dom");

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = screenWidth <= 900;
  const hideMenu = () => {
    isMobile && setIsOpen(false);
  };

  const navigation = (
    <div id="rdosidenav">
      <nav id="sidenav">
        {!isMobile && <img id="logo" src="/rdo-logo-whiteOnBlack.png" style={{ marginTop: 20 }} />}
        <NavLink to={"/sms"} onClick={hideMenu}>
          <div className={"navlinkitem"}>
            <Icon iconName="Message" /> Send SMS
          </div>
        </NavLink>
        <NavLink to={"/history"} onClick={hideMenu}>
          <div className={"navlinkitem"}>
            <Icon iconName="History" /> Sent Messages
          </div>
        </NavLink>

        {screenHeight > 600 && (
          <div style={{ marginTop: 50 }}>
            <NavLink to={"/azuretenants"} onClick={hideMenu}>
              <div className={"navlinkitem"}>
                <Icon iconName="AzureLogo" /> Azure Tenants
              </div>
            </NavLink>
            <NavLink to={"/contactgroups"} onClick={hideMenu}>
              <div className={"navlinkitem"}>
                <Icon iconName="ContactList" /> Contact Groups
              </div>
            </NavLink>
            <NavLink to={"/contacts"}>
              <div className={"navlinkitem"} onClick={hideMenu}>
                <Icon iconName="ContactInfo" /> Contacts
              </div>
            </NavLink>
            <NavLink to={"/fromNumbers"}>
              <div className={"navlinkitem"} onClick={hideMenu}>
                <Icon iconName="NumberSymbol" /> Sender Numbers
              </div>
            </NavLink>
            <NavLink to={"/templates"}>
              <div className={"navlinkitem"} onClick={hideMenu}>
                <Icon iconName="TextDocument" /> Templates
              </div>
            </NavLink>
          </div>
        )}

        <div className="navlinkitembottom">
          <div className="navlinkitem" onClick={props.signOut}>
            <Icon iconName="SignOut" /> Sign Out
          </div>
        </div>
      </nav>
    </div>
  );

  if (isMobile)
    return (
      <>
        <TopBar
          onMenuOpenClick={() => {
            setIsOpen(true);
          }}
        ></TopBar>
        <Panel
          type={PanelType.customNear}
          isOpen={isOpen}
          customWidth={`${screenWidth - 100}px`}
          onDismiss={() => setIsOpen(false)}
          styles={{
            main: { backgroundColor: "black" },
            header: { backgroundColor: "black" },
            commands: { backgroundColor: "black" },
            subComponentStyles: {
              closeButton: { icon: { color: "white", fontWeight: "bold" }, rootHovered: { backgroundColor: "#333333" } },
            },
          }}
        >
          {navigation}
        </Panel>
      </>
    );

  return navigation;
};

export default NavBar;
