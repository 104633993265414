/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShortUrl = /* GraphQL */ `
  query GetShortUrl($id: ID!) {
    getShortUrl(id: $id) {
      id
      shortCode
      s3ObjectFullKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShortUrls = /* GraphQL */ `
  query ListShortUrls(
    $filter: ModelShortUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShortUrls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shortCode
        s3ObjectFullKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMSMessage = /* GraphQL */ `
  query GetSMSMessage($id: ID!) {
    getSMSMessage(id: $id) {
      id
      senderName
      from
      message
      fileKey
      fileName
      messageType
      recipients {
        mobileNumber
        name
        delivered
        __typename
      }
      contactGroupRecipients {
        name
        azureId
        azureName
        azureTenantName
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSMSMessages = /* GraphQL */ `
  query ListSMSMessages(
    $filter: ModelSMSMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMSMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderName
        from
        message
        fileKey
        fileName
        messageType
        recipients {
          mobileNumber
          name
          delivered
          __typename
        }
        contactGroupRecipients {
          name
          azureId
          azureName
          azureTenantName
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageTemplate = /* GraphQL */ `
  query GetMessageTemplate($id: ID!) {
    getMessageTemplate(id: $id) {
      id
      templateName
      templateBody
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessageTemplates = /* GraphQL */ `
  query ListMessageTemplates(
    $filter: ModelMessageTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateName
        templateBody
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSendFromNumber = /* GraphQL */ `
  query GetSendFromNumber($id: ID!) {
    getSendFromNumber(id: $id) {
      id
      number
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSendFromNumbers = /* GraphQL */ `
  query ListSendFromNumbers(
    $filter: ModelSendFromNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSendFromNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      azureId
      name
      email
      branch
      mobileNumber
      contactgroups {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        azureId
        name
        email
        branch
        mobileNumber
        contactgroups {
          nextToken
          __typename
        }
        azureTenantId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactsByAzureTenantId = /* GraphQL */ `
  query ContactsByAzureTenantId(
    $azureTenantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactsByAzureTenantId(
      azureTenantId: $azureTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        azureId
        name
        email
        branch
        mobileNumber
        contactgroups {
          nextToken
          __typename
        }
        azureTenantId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactGroup = /* GraphQL */ `
  query GetContactGroup($id: ID!) {
    getContactGroup(id: $id) {
      id
      name
      azureName
      isFavorite
      azureId
      contacts {
        items {
          id
          contactId
          contactGroupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      azureTenantId
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactGroups = /* GraphQL */ `
  query ListContactGroups(
    $filter: ModelContactGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        azureName
        isFavorite
        azureId
        contacts {
          nextToken
          __typename
        }
        azureTenantId
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactGroupsByAzureTenantId = /* GraphQL */ `
  query ContactGroupsByAzureTenantId(
    $azureTenantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactGroupsByAzureTenantId(
      azureTenantId: $azureTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        azureName
        isFavorite
        azureId
        contacts {
          nextToken
          __typename
        }
        azureTenantId
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAzureTenant = /* GraphQL */ `
  query GetAzureTenant($id: ID!) {
    getAzureTenant(id: $id) {
      id
      name
      azureTenantId
      azureAppId
      azureAppSecret
      Contacts {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      contactGroups {
        items {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      syncStatus
      lastSyncTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAzureTenants = /* GraphQL */ `
  query ListAzureTenants(
    $filter: ModelAzureTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAzureTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        azureTenantId
        azureAppId
        azureAppSecret
        Contacts {
          nextToken
          __typename
        }
        contactGroups {
          nextToken
          __typename
        }
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContactGroupContact = /* GraphQL */ `
  query GetContactGroupContact($id: ID!) {
    getContactGroupContact(id: $id) {
      id
      contactId
      contactGroupId
      contact {
        id
        azureId
        name
        email
        branch
        mobileNumber
        contactgroups {
          nextToken
          __typename
        }
        azureTenantId
        createdAt
        updatedAt
        __typename
      }
      contactGroup {
        id
        name
        azureName
        isFavorite
        azureId
        contacts {
          nextToken
          __typename
        }
        azureTenantId
        syncStatus
        lastSyncTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listContactGroupContacts = /* GraphQL */ `
  query ListContactGroupContacts(
    $filter: ModelContactGroupContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactGroupContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactId
        contactGroupId
        contact {
          id
          azureId
          name
          email
          branch
          mobileNumber
          azureTenantId
          createdAt
          updatedAt
          __typename
        }
        contactGroup {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactGroupContactsByContactId = /* GraphQL */ `
  query ContactGroupContactsByContactId(
    $contactId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactGroupContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactGroupContactsByContactId(
      contactId: $contactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactId
        contactGroupId
        contact {
          id
          azureId
          name
          email
          branch
          mobileNumber
          azureTenantId
          createdAt
          updatedAt
          __typename
        }
        contactGroup {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const contactGroupContactsByContactGroupId = /* GraphQL */ `
  query ContactGroupContactsByContactGroupId(
    $contactGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContactGroupContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactGroupContactsByContactGroupId(
      contactGroupId: $contactGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactId
        contactGroupId
        contact {
          id
          azureId
          name
          email
          branch
          mobileNumber
          azureTenantId
          createdAt
          updatedAt
          __typename
        }
        contactGroup {
          id
          name
          azureName
          isFavorite
          azureId
          azureTenantId
          syncStatus
          lastSyncTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
